<script>
import { mapState } from 'vuex'

export default {
  name: 'Searchbar',
  components: {
    Action: () => import('@/components/general/Action'),
    Icon: () => import('@/components/general/Icon'),
    InputField: () => import('@/components/general/InputField')
  },
  props: {
    classes: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      isOpen: false,
      formData: null
    }
  },
  methods: {
    toggleSearch () {
      this.isOpen = !this.isOpen
      this.$nextTick(() => {
        this.$refs.inputfield && this.$refs.inputfield.$refs.input.focus()
      })
    },
    outside () {
      this.isOpen = false
    }
  },
  computed: {
    ...mapState(['accessibility'])
  }
}
</script>

<template>
  <div
    v-click-outside="outside"
    class="search-bar"
    :class="{ 'is-open': isOpen }"
  >
    <action
      type="link"
      icon="search"
      class="search-bar-link"
      :class="[classes]"
      @click="toggleSearch"
    />
    <div
      v-if="isOpen"
      class="search-form"
    >
      <form>
        <input-field
          ref="inputfield"
          v-model="formData"
          :label="label"
          :floating-label="false"
          dark
          autofocus
        />
      </form>
    </div>
  </div>
</template>

<style src="@/assets/styles/themes/default/searchbar.css"></style>
